<template>
  <div class="app_con">
    <div class="formCon">
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agentCodeList: [],
      form: {
        name: "",
        pid: 0,
        description: "",
        isShow: 0,
        icon: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
